<template>
    <div class="os-register" @click="viewClick">
        <v-head currentIndex=0></v-head>
        <div class="container">
            <div class="content">
                <img src="../../../assets/imgs/img_doctor_reg_banner.png" alt=""/>
                <div class="steps">
                    <div :class="{'step': true, 'current': step==1}" :style="{'cursor': step >= 1 ? 'pointer': undefined}" @click="changeStep(1)">
                        <div class="line"></div>
                        <span class="count">1</span>
                        <div class="desc">註冊須知</div>
                    </div>
                    <div :class="{'step': true, 'current': step==2}" :style="{'cursor': step >= 2 ? 'pointer': undefined}" @click="changeStep(2)">
                        <div class="line"></div>
                        <span class="count">2</span>
                        <div class="desc">個人信息</div>
                    </div>
                    <div :class="{'step': true, 'current': step==3}" :style="{'cursor': step >= 3 ? 'pointer': undefined}" @click="changeStep(3)">
                        <div class="line"></div>
                        <span class="count">3</span>
                        <div class="desc">您的診所</div>
                    </div>
                    <div :class="{'step': true, 'current': step==4}" @click="changeStep(4)">
                        <div class="line"></div>
                        <span class="count">4</span>
                        <div class="desc">登錄信息</div>
                    </div>
                </div>
                <div class="tabviews">
                    <div class="tabview" v-if="step==1">
                        <div class="page1">
                            <div class="col">
                                <div class="head">為什麼要註冊</div>
                                <p>
                                    1、醫師註冊並加入診所後，可享用中醫診所管理平臺，提供<br>診療一條龍服務。<br>
                                    2、在本網站展示您的診所和醫師，並可在綫預約掛號。<br>
                                    3、您可在綫回答健康問答。<br>
                                    4、您可發布健康資訊。<br>
                                    5、更多的就業機會。
                                </p>
                            </div>
                            <div class="col">
                                <div class="head">註冊須知</div>
                                <p>
                                    1、本平臺保證合作的真實與合法性，不會將您的信息資料透<br>露給第三方。<br>
                                    2、請您提供真實有效的實名身份信息和證件。<br>
                                    3、我們將人工審核您資料的有效性。<br>
                                    4、您有任何疑問，請諮詢客服。<br>
                                </p>
                            </div>
                        </div>
                        <div class="btn-next" @click="doRegister">開始註冊</div>
                        <div class="success" v-if="isSuccess">
                            <div class="row">
                               <img src="../../../assets/imgs/pc/ok.png" alt="">
                               <span>恭喜您，賬號申請已提交，請等候審核!</span>
                            </div>
                            <div class="row notice">
                                請把證件掃描件發送到至Whatsapp:93839432，或電郵至info@ectcm.com
                            </div>
                        </div>
                    </div>
                    <div class="tabview" v-if="step==2">
                        <div class="page2">
                            <div class="form-item name">
                                <span class="label">您的姓名<i>*</i></span>
                                <input type="text" class="value" v-model="formData.username">
                                <span class="input-notice">星号<i>*</i>標識的信息默認會公開在網站的診所/醫師頁面</span>
                            </div>
                            <div class="form-item">
                                <span class="label">性别<i>*</i></span>
                                <div class="value">
                                    <span :class="{'checkbox': true, 'checked': formData.sex==2}" @click="changeFormData('sex', 2)">女</span>
                                    <span :class="{'checkbox': true, 'checked': formData.sex==1}" @click="changeFormData('sex', 1)">男</span>
                                </div>
                            </div>
                            <div class="form-item">
                                <span class="label">出生年月</span>
                                <div class="date value" :data-value="formData.birthdate" @click="pickerDateTime">{{formData.birthdate || '请选择出生年月'}}</div>
                            </div>
                            <div class="form-item">
                                <span class="label">是否為中醫師<i>*</i></span>
                                <div class="value">
                                    <span :class="{'checkbox': true, 'checked': formData.isdoctor == true}" @click="changeFormData('isdoctor', true)">是</span>
                                    <span :class="{'checkbox': true, 'checked': formData.isdoctor == false}" @click="changeFormData('isdoctor', false)">否</span>
                                </div>
                            </div>
                            <div class="form-item" v-if="formData.isdoctor===true">
                                <span class="label">醫師編號<i>*</i></span>
                                <input type="text" class="value" placeholder="醫師資格證編號（6个字符）" v-model="formData.doctorno">
                            </div>
                            <div class="form-item">
                                <span class="label">證件掃描</span>
                                <input type="text" class="value" style="width: 50%" placeholder="http://" v-model="fileUrl">
                                <div class="btn-upload" style="right: 0" @click="uploadFile"><div class="lds-ring" v-if="isUploading"><div></div><div></div><div></div><div></div></div>{{isUploading ? '正在上傳...' : (formData.attachment ? '已上傳' : '附件≤5M')}}</div>
                            </div>
                            <div class="form-item">
                                <span class="label">Email<i>*</i></span>
                                <input type="text" class="value" v-model="formData.email">
                            </div>
                            <div class="form-item">
                                <span class="label">聯繫電話<i>*</i></span>
                                <input type="text" class="value" v-model="formData.contactnum">
                            </div>
                        </div>
                        <div class="btn-step2" @click="doRegister">下一步</div>
                    </div>
                    <div class="tabview" v-if="step==3">
                        <div class="page3">
                            <div class="form-item">
                                <span class="label">診所名稱<i v-if="formData.isdoctor==false">*</i></span>
                                <input type="text" class="value" v-model="formData.companyname">
                            </div>
                            <div class="form-item">
                                <span class="label">商業登記證編號</span>
                                <input type="text" class="value" v-model="formData.regno">
                            </div>
                            <div class="form-item">
                                <span class="label">上班週期</span>
                                <div class="value choose" :data-value="formData.workdays" @click.stop="onWorkWeekClick">
                                    {{formData.workdays ? `${weeks.filter(w=>w.checked).map(w=>w.label).join(', ')}` : '選擇上班週期'}}
                                    <div class="options" v-if="showWorkWeek">
                                        <div :class="{'option': true, 'checked': w.checked}" @click.stop="onWeekItemClick(w)" v-for="(w, i) in weeks" :key="i" v-text="w.label"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-item work-time">
                                <span class="label">上班時間</span>
                                <div class="value">
                                    <input type="text" class="start" format="HH:mm" v-model="formData.workstarttime" placeholder="例如 10:00" onfocus="(this.type='time')" onblur="(this.type='text')">
                                    <span class="value-split">至</span>
                                    <input type="text" class="end" format="HH:mm" v-model="formData.workendtime" placeholder="例如 11:30" onfocus="(this.type='time')" onblur="(this.type='text')">
                                </div>
                                <input type="text" class="time-note" placeholder="上班時間備註"  v-model="formData.worktimeremark">
                            </div>
                            <div class="form-item">
                                <span class="label">Email</span>
                                <input type="email" class="value" v-model="formData.clinicemail">
                            </div>
                            <div class="form-item">
                                <span class="label">聯繫電話<i v-if="formData.isdoctor==false">*</i></span>
                                <input type="tel" class="value" v-model="formData.cliniccontactnum">
                            </div>
                            <div class="form-item area">
                                <span class="label">所在區域<i>*</i></span>
                                <div class="value">
                                    <div class="value-item">
                                        <div class="select" :data-value="formData.arealevel1id || ''" @click.stop="onArea1Click" v-text="area1Name"></div>
                                        <div class="options" v-if="showArea1">
                                            <div class="option" v-for="(area, i) in area1Datas" :key="i" v-text="area.codedesc" @click="onArea1ItemClick(area)"></div>
                                        </div>
                                    </div>
                                    <div class="value-item">
                                        <div class="select" :data-value="formData.arealevel2id || ''" @click.stop="onArea2Click" v-text="area2Name"></div>
                                        <div class="options" v-if="showArea2">
                                            <div class="option" v-for="(area, i) in area2Datas" :key="i" v-text="area.codedesc" @click="onArea2ItemClick(area)"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-item">
                                <span class="label">地址</span>
                                <input type="text" class="value" v-model="formData.address">
                            </div>
                            <div class="form-item">
                                <span class="label">簡介</span>
                                <textarea cols="30" rows="3" class="value" placeholder="個人或診所介紹" v-model="formData.note"></textarea>
                            </div>
                        </div>
                        <div class="btn-step2" @click="doRegister">下一步</div>
                    </div>
                    <div class="tabview" v-if="step==4">
                        <div class="page3">
                            <div class="form-item">
                                <span class="label">登录名<i>*</i></span>
                                <input type="text" class="value" placeholder="登錄診所管理系統的用戶名" v-model="formData.loginname">
                            </div>
                            <div class="form-item">
                                <span class="label">密码<i>*</i></span>
                                <input type="password" class="value" placeholder="以字母開頭的6~15位字符" v-model="formData.password">
                            </div>
                            <div class="form-item">
                                <span class="label">确认密码<i>*</i></span>
                                <input type="password" class="value" placeholder="再次確認密碼" v-model="formData.password2">
                            </div>
                            <div class="form-item">
                                <span class="label">診所邀請碼</span>
                                <input type="number" class="value" placeholder="推薦人提供的邀請碼(4位數字)" v-model="formData.invitationcode">
                            </div>
                        </div>
                        <div :class="{'protocol': true, 'checked': isAgree}" @click="changeAgree">
                            <div class="checkbox"></div>
                            <div>我已阅读并接受本站<router-link target="_blank" class="link" to='/home/privacy'>隱私條款</router-link>和<router-link target="_blank" class="link" to="/home/disclaimer">免責聲明</router-link>！</div>
                        </div>
                        <div class="btn-register" @click="doRegister"><div class="lds-ring" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在註冊...' : '立即註冊'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <form ref="form" style="display: none;">
            <input type="file" ref="file" id="file" @change="onFileChanged">
        </form>
        <van-popup
            v-model="show"
            position="bottom"
            :style="{ height: '30%' }"
        >
            <van-datetime-picker
                v-model="currentDate"
                type="date"
                :visible-item-count="3"
                :min-date="minDate"
                :max-date="maxDate"
                :formatter="formatter"
                @cancel="onDateTimePickCancel"
                @confirm="onDateTimePickConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
    import vHead from '@/layouts/MobileHead.vue'
	export default {
		components: {
			vHead,
		},
        data: function(){
            return {
                show: false,
                minDate: new Date(1900, 0, 1),
                maxDate: new Date(),
                currentDate: new Date(),
                isUploading: false,
                file: null,
                step: 1,
                isSuccess: false,
                readonly: undefined,
                isAgree: false,
                showArea1: false,
                showArea2: false,
                showWorkWeek: false,
                area1Datas: [],
                area2Datas: [],
                area1Name: '',
                area2Name: '',
                weeks: [
                    {
                        label: '週一',
                        checked: false,
                        index: 1,
                    },{
                        label: '週二',
                        checked: false,
                        index: 2,
                    },{
                        label: '週三',
                        checked: false,
                        index: 3,
                    },{
                        label: '週四',
                        checked: false,
                        index: 4,
                    },{
                        label: '週五',
                        checked: false,
                        index: 5,
                    },{
                        label: '週六',
                        checked: false,
                        index: 6,
                    },{
                        label: '週日',
                        checked: false,
                        index: 7,
                    },
                ],
                fileUrl: undefined,
                formData: {
                    "address": undefined,//address	string 地址(200字符内)
                    "arealevel1id": undefined,// arealevel1id	integer($int32) 地區1对应的id
                    "arealevel2id": undefined,// arealevel2id	integer($int32) 地區2对应的id
                    "attachment": undefined,// attachment	string 證件掃描件的url后半段地址
                    "birthdate": undefined,// birthdate	string($date-time) 出生日期(yyyy-MM-dd)
                    "cliniccontactnum": undefined,// cliniccontactnum	string 診所電話
                    "clinicemail": undefined,// clinicemail	string 診所Email
                    "companyname": undefined,// companyname	string 診所名稱(3-100个字符)
                    "contactnum": undefined,// contactnum	string 联系电话
                    "doctorno": undefined,// doctorno	string 醫師編號
                    "email": undefined,// email	string email
                    "invitationcode": undefined,// invitationcode	string 診所邀請碼(4位纯数字)
                    "isdoctor": undefined,// isdoctor	boolean 是否为中医师
                    "loginname": undefined,// loginname	string 登錄名(為以字母開头的6~15位字符)
                    "note": undefined,// note	string 簡介(500字符内)
                    "password": undefined,// password	string 密码(以字母開头的6~15位字符)
                    "password2": undefined,// password2	string 确认密码(以字母開头的6~15位字符)
                    "regno": undefined,// regno	string 商業登記證編號(7~9位字母數字组合)
                    "sex": undefined,// sex	integer($int32)  性别 1男 2女
                    "useridcard": undefined,// useridcard	string 身份證號
                    "username": undefined,// username	string 姓名(25个字符内)
                    "workdays": undefined,// workdays	string 多个工作日期，用逗号隔开，如：1,2,3,4,5,6,7
                    "workendtime": undefined,// workendtime	string 工作结束时间,如：21:00:00
                    "workendweekday": undefined,// workendweekday	integer($int32) 診所一周工作结束日(1-7分别代表星期一到星期日)
                    "workstarttime": undefined,// workstarttime	string 工作开始时间,如：09:00:00
                    "workstartweekday": undefined,// workstartweekday	integer($int32) 診所一周工作开始日(1-7分别代表星期一到星期日)
                    "worktimeremark": undefined,// worktimeremark	string 工作结束时间备注信息
                },
                loading: false,
            }
        },
        mounted: function(){
            if(! this.area1Datas.length) {
                //获取地区
                this.$store.dispatch('getAreaList', {'langcode': 'B5'}).then((data)=>{
                    this.area1Datas = data
                })
            }
        },
        methods: {
            formatter: function(type, val) {
                if (type === 'year') {
                    return `${val}年`;
                } else if (type === 'month') {
                    return `${val}月`;
                }
                return `${val}日`;
            },
            pickerDateTime: function(){
                this.show = !this.show
            },
            onDateTimePickCancel: function(){
                this.show = false
            },
            onDateTimePickConfirm: function(d){
                this.show = false
                var month = '' + (d.getMonth() + 1)
                var day = '' + d.getDate()
                var year = d.getFullYear()
                if (month.length < 2)
                    month = '0' + month
                if (day.length < 2)
                    day = '0' + day;
                var formData = {...this.formData}
                formData.birthdate = [year, month, day].join('-')
                this.formData = formData
            },
            onFileChanged: function(v){
                if(!v) return
                if(this.isUploading) return
                var files = this.$refs.file.files
                if(!files || !files.length) return
                this.formData.attachment = undefined
                this.isUploading = true
                var formData = new FormData()
                formData.append("files", files[0]);
                this.$store.dispatch('uploadImage', {imgCategory: 8, form: formData})
                .then((rs)=>{
                    this.isUploading = false
                    this.formData.attachment = rs[0]
                    this.readonly = 'readonly'
                    this.$refs.file.value = ""
                }).catch(error => {
                    this.isUploading = false
                    this._toast.warning(this, error || '附件上傳失敗')
                    this.$refs.file = ""
                })
            },
            changeFormData: function(key, value){
                var formData = {...this.formData}
                formData[key] = value
                this.formData = formData
                if(key == 'isdoctor' && value === false) {
                    this.formData.doctorno = undefined
                }
            },
            uploadFile: function(){
                if(this.isUploading) return
                this.$refs.file.click && this.$refs.file.click()
            },
            onArea1ItemClick: function(value){
                this.formData.arealevel1id = value.codeid
                this.area1Name = value.codedesc

                this.formData.arealevel2id = undefined
                this.area2Name = ''
                
                this.$store.dispatch('getAreaList', {'langcode': 'B5', 'parentId': value.codeid})
                .then((data)=>{
                    this.area2Datas = data || []
                })
            },
            onArea2ItemClick: function(value){
                this.formData.arealevel2id = value.codeid
                this.area2Name = value.codedesc
            },
            onWeekItemClick: function(w){
                w.checked = !w.checked
                this.formData.workdays = [
                    ...this.weeks.filter(w=>w.checked).map(w=>w.index)
                ].join(',')
            },
            onWorkWeekClick: function(){
                this.showArea1 = false
                this.showArea2 = false
                this.showWorkWeek = !this.showWorkWeek
            },
            onArea1Click: function(){
                this.showArea2 = false
                this.showArea1 = !this.showArea1
            },
            onArea2Click: function(){
                this.showArea1 = false
                this.showArea2 = !this.showArea2
            },
            viewClick: function() {
                this.showArea1 = false
                this.showArea2 = false
                this.showWorkWeek = false
            },
            doRegister: function() {
                if(this.step == 1) {
                    this.step ++
                    this.isSuccess = false
                } else if(this.step == 2) {
                    if(!this.formData.username || !this.formData.username.trim()) {
                        this._toast.warning(this, '請輸入您的姓名')
                        return
                    }
                    if(!this.formData.sex) {
                        this._toast.warning(this, '請選擇性別')
                        return
                    }
                    if(Object.prototype.toString.call(this.formData.isdoctor) !== '[object Boolean]') {
                        this._toast.warning(this, '請選擇是否為中醫師')
                        return
                    }
                    if(this.formData.isdoctor === true && ! /^[a-zA-Z0-9]{6}$/.test(this.formData.doctorno.trim())) {
                        this._toast.warning(this, '請輸入醫師編號(6个字符)')
                        return
                    }
                    if(!this.formData.email) {
                        this._toast.warning(this, 'Email不能為空')
                        return
                    }
                    if(! /^[-_a-zA-Z0-9\.]{3,}@[a-zA-Z0-9_-]{2,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/.test(this.formData.email.trim())) {
                        this._toast.warning(this, 'Email格式不正確')
                        return
                    }
                    if(!this.formData.contactnum) {
                        this._toast.warning(this, '請輸入聯繫電話')
                        return
                    }
                    // if(! /^1[3-9]{1}[0-9]{9}$/.test(this.formData.contactnum) && ! /^([5|6|8|9])\d{7}$/.test(this.formData.contactnum)) {
                    //     this._toast.warning(this, '聯繫電話格式不正確')
                    //     return
                    // }
                    if(! /\d{7}$/.test(this.formData.contactnum.trim())) {
                        this._toast.warning(this, '聯繫電話格式不正確')
                        return
                    }
                    this.step ++
                } else if(this.step == 3) {
                    if(this.formData.isdoctor == false) {
                        if(!this.formData.companyname || !this.formData.companyname.trim()) {
                            this._toast.warning(this, '請輸入診所名稱')
                            return
                        }
                        if(this.formData.regno && ! /^[a-zA-Z0-9]{5,}$/.test(this.formData.regno.trim())) {
                            this._toast.warning(this, '’商業登記證編號‘輸入不正確')
                            return
                        }
                        if(this.formData.clinicemail && ! /^[-_a-zA-Z0-9\.]{3,}@[a-zA-Z0-9_-]{2,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/.test(this.formData.clinicemail.trim())) {
                            this._toast.warning(this, 'Email格式不正確')
                            return
                        }
                        // if(! /^1[3-9]{1}[0-9]{9}$/.test(this.formData.cliniccontactnum) && ! /^([5|6|8|9])\d{7}$/.test(this.formData.cliniccontactnum)) {
                        //     this._toast.warning(this, '聯繫電話格式不正確')
                        //     return
                        // }
                        if(this.formData.cliniccontactnum && ! /\d{7}$/.test(this.formData.cliniccontactnum.trim())) {
                            this._toast.warning(this, '聯繫電話格式不正確')
                            return
                        }
                    } else {
                        if(this.formData.clinicemail && ! /^[-_a-zA-Z0-9\.]{3,}@[a-zA-Z0-9_-]{2,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/.test(this.formData.clinicemail.trim())) {
                            this._toast.warning(this, 'Email格式不正確')
                            return
                        }
                    }
                    // if(this.formData.cliniccontactnum && ! /^1[3-9]{1}[0-9]{9}$/.test(this.formData.contactnum) && ! /^([5|6|8|9])\d{7}$/.test(this.formData.cliniccontactnum)) {
                    //     this._toast.warning(this, '聯繫電話格式不正確')
                    //     return
                    // }
                    if(this.formData.cliniccontactnum && ! /\d{7}$/.test(this.formData.cliniccontactnum.trim())) {
                        this._toast.warning(this, '聯繫電話格式不正確')
                        return
                    }
                    if(!this.formData.arealevel1id || !this.formData.arealevel2id) {
                        this._toast.warning(this, '請選擇所在區域')
                        return
                    }
                    this.step ++
                } else {
                    if(!this.formData.loginname || ! /^[_a-zA-Z0-9]{6,15}$/.test(this.formData.loginname.trim())) {
                        this._toast.warning(this, '請輸入有效的用戶名(字母開頭的6~15位字符)')
                        return
                    }
                    if(!this.formData.password || ! /^[_a-zA-Z0-9]{6,15}$/.test(this.formData.password)) {
                        this._toast.warning(this, '請輸入有效的密碼(字母開頭的6~15位字符)')
                        return
                    }
                    if(!this.formData.password2 || this.formData.password2 != this.formData.password) {
                        this._toast.warning(this, '兩次密碼輸入不一致')
                        return
                    }
                    if(this.formData.invitationcode && ! /^\d{4}$/.test(this.formData.invitationcode)) {
                        this._toast.warning(this, '診所邀請碼必須為4位數字')
                        return
                    }
                    if(!this.isAgree) {
                        this._toast.warning(this, '請勾選‘我已阅读并接受本站隱私條款和免責聲明’')
                        return
                    }
                    if(this.formData.invitationcode) {
                        this.formData.invitationcode = parseInt(this.formData.invitationcode)
                    }
                    if(this.loading) return
                    if(this.fileUrl && !this.formData.attachment) {
                        this.formData.attachment = this.fileUrl
                    }
                    if(this.formData.address) {
                        this.formData.address = this.formData.address.trim()
                    }
                    if(this.formData.note) {
                        this.formData.note = this.formData.note.trim()
                    }
                    if(this.formData.email) {
                        this.formData.email = this.formData.email.trim()
                    }
                    if(this.formData.cliniccontactnum) {
                        this.formData.cliniccontactnum = this.formData.cliniccontactnum.trim()
                    }
                    if(this.formData.clinicemail) {
                        this.formData.clinicemail = this.formData.clinicemail.trim()
                    }
                    if(this.formData.regno) {
                        this.formData.regno = this.formData.regno.trim()
                    }
                    if(this.formData.contactnum) {
                        this.formData.contactnum = this.formData.contactnum.trim()
                    }
                    if(this.formData.username) {
                        this.formData.username = this.formData.username.trim()
                    }
                    if(this.formData.companyname) {
                        this.formData.companyname = this.formData.companyname.trim()
                    }
                    if(this.formData.doctorno) {
                        this.formData.doctorno = this.formData.doctorno.trim()
                    }
                    this.formData.loginname = this.formData.loginname.trim()
                    this.loading = true
                    this.$store.dispatch('createOSCount', this.formData)
                    .then((rs) => {
                        this.loading = false
                        this.step = 1
                        this.area1Name = null
                        this.area2Name = null
                        this.formData = {}
                        this.fileUrl = undefined
                        this.isAgree = false
                        this._toast.success(this, '賬號註冊申請已提交！')
                        this.isSuccess = true
                    }).catch((error) => {
                        this.loading = false
                        this._toast.warning(this, error || '操作失敗')
                    })
                }
            },
            changeStep: function(step) {
                if(this.step > step) {
                    this.step = step
                }
            },
            changeAgree: function(){
                this.isAgree = !this.isAgree
            }
        }
	}
</script>

<style lang="scss" scoped>
    .os-register {
        width: 100%;
	    padding-top: 83px;
    }

    .os-register > .container {
        min-height: 652px;
        background-color: #fff;
        margin-top: 10px;
    }

    .os-register > .container > .content {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }
    .os-register > .container > .content > img {
        display: block;
        height: 88px;
        object-fit: contain;
        user-select: none;
        margin: 12px 12px 6px 12px;
        cursor: pointer;
    }

    .content > .steps {
        display: flex;
        flex-direction: row;
        height: 80px;
    }

    .content > .steps > .step {
        position: relative;
        flex: 1;
    }

    .content > .steps > .step > .line {
        width: 100%;
        height: 8px;
        background-color: #E8E8E8;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .content > .steps > .step.current > .line {
        background-color: #36C4D0
    }

    .content > .steps > .step > .count {
        background-color: #E8E8E8;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 26px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        border-radius: 50%;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
    }

    .content > .steps > .step.current > .count {
        background-color: #36C4D0
    }

    .content > .steps > .step > .desc {
        text-align: center;
        line-height: 80px;
        padding-top: 26px;
        color: #E8E8E8;
        font-size: 24px;
        font-weight: bold;
        user-select: none;
    }

    .content > .steps > .step.current > .desc {
        color: #36C4D0;
        font-size: 28px;
    }

    .content > .tabviews > .tabview {
        display: flex;
        flex-direction: column;
    }

    .content > .tabviews > .tabview > div {
        width: 90%;
    }

    .tabviews .page1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 90px 36px 0px 36px;
    }

    .tabviews .page1 > .col {
        width: 373px;
        display: flex;
        flex-direction: column;
    }

    .tabviews .page1 > .col:nth-child(1) {
        margin-right: 12px;
    }

    .tabviews .page1 > .col > .head {
        width: 197px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        color: #231F20;
        font-weight: bold;
        font-size: 28px;
        background-color: #EEF9F3;
        margin-bottom: 20px;
        align-self: center;
    }

    .tabviews .page1 > .col > p {
        color: #231F20;
        font-size: 24px;
        font-weight: 400;
    }
    
    .tabviews .btn-register,
    .tabviews .btn-next {
        width: 270px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        background-color: #36C4D0;
        color: #fff;
        border-radius: 5px;
        color: #fff;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
        align-self: center;
        margin-top: 174px;
        margin-bottom: 50px;
        user-select: none;
    }

    .tabviews .btn-register {
        margin-top: 20px;
    }

    .tabviews .btn-register:active,
    .tabviews .btn-next:active {
        opacity: 0.7;
    }

    .tabviews .page2,
    .tabviews .page3 {
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-top: 39px;
    }

    .form-item {
        display: flex;
        flex-direction: row;
        height: 50px;
        margin-bottom: 32px;
        position: relative;
    }

    .form-item.work-time {
        padding-bottom: 50px;
    }

    .form-item.name {
        padding-top: 50px;
    }

    .form-item.name .input-notice {
        right: 0;
        top: 0px;
    }

    .protocol {
        align-self: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 69px;
        cursor: pointer;
        font-size: 28px;
        font-weight: 400;
        color: #333333;
        align-items: center;
        justify-content: center;
        user-select: none;
    }

    .protocol.checked {
        color: #36C4D0;
    }

    .protocol .link {
        font-size: 28px;
        font-weight: 400;
        color: #333333;
        text-decoration: underline;
    }

    .protocol.checked .link {
        color: #36C4D0;
    }

    .protocol > .checkbox {
        position: relative;
        width: 28px;
        height: 28px;
        border: 1px solid #333333;
        margin-right: 6px;
    }
    
    .protocol.checked > .checkbox {
        border-color: transparent;
        background-image: url(../../../assets/imgs/pc/icon_xuanze@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .form-item > .time-note {
        position: absolute;
        left: 200px;
        right: unset;
        top: 58px;
        width: 73%;
        height: 50px;
        line-height: 50px;
        padding-left: 12px;
        box-sizing: border-box;
        color: #333333;
        font-size: 28px;
        font-weight: bold;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
    }

    .form-item > .input-notice {
        position: absolute;
        right: -333px;
        color: #999999;
        font-weight: 400;
        font-size: 14px;
        height: 50px;
        line-height: 50px;
    }

    .form-item > .input-notice > i {
        font-style: normal;
        color: #FF0000;
        font-weight: bold;
        font-size: 28px;
    }
    
    .form-item > .value {
        flex: 1;
    }

    .form-item > .value.date,
    .form-item > input.value {
        border: 1px solid #E8E8E8;
        padding-left: 12px;
        height: 50px;
        line-height: 50px;
        border-radius: 8px;
        color: #333333;
        font-size: 28px;
        font-weight: 400;
        box-sizing: border-box;
    }

    .form-item > textarea.value {
        border: 1px solid #E8E8E8;
        border-radius: 8px;
        color: #333333;
        font-size: 28px;
        padding: 8px 12px;
        height: 64px;
        font-weight: 400;
        box-sizing: border-box;
    }

    .form-item > .time-note::placeholder,
    .form-item > textarea.value::placeholder,
    .form-item > input.value::placeholder {
        font-size: 28px;
        font-weight: 400;
        color: #999999;
    }

    .form-item input[type='time']::-webkit-calendar-picker-indicator,
    .form-item input[type='date']::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background-image: url(../../../assets/imgs/pc/icon-rili@2x.png);
        background-repeat: no-repeat;
        background-size: 50% 50%;
        width: 44px;
        height: 44px;
        border-width: 0;
        position: absolute;
        right: -20px;
        top: 3px;
        z-index: 10;
        outline: none;
    }

    .form-item > div.value {
        display: flex;
        flex-direction: row;
        height: 50px;
        line-height: 50px;
        align-items: center;
    }

    .form-item > div.value > input.start,
    .form-item > div.value > input.end {
        width: 46%;
        height: 50px;
        padding-left: 12px;
        line-height: 50px;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
        position: relative;
        color: #333333;
        font-size: 28px;
        font-weight: 400;
        box-sizing: border-box;
    }

    .form-item > div.value.choose {
        border: 1px solid #E8E8E8;
        padding-left: 12px;
        height: 50px;
        padding-right: 12px;
        line-height: 50px;
        border-radius: 8px;
        color: #333333;
        font-size: 28px;
        flex: 1;
        font-weight: 400;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .form-item > div.value.choose[data-value=""] {
        font-size: 28px;
        font-weight: 400;
        color: #999999;
    }
    
    .form-item > div.value > .checkbox {
        color: #333333;
        font-weight: 400;
        font-size: 32px;
        position: relative;
        padding-left: 38px;
        line-height: 32px;
        margin-left: 30px;
        cursor: pointer;
    }

    .form-item > div.value > .checkbox.checked {
        color: #36C4D0;
    }

    .form-item > div.value > .checkbox:nth-child(2) {
        margin-left: 110px;
    }

    .form-item > div.value > .checkbox::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 32px;
        height: 32px;
        border: 1px solid #ddd;
        border-radius: 50%;
        box-sizing: border-box;
    }

    .form-item > div.value > .checkbox.checked::after {
        border: 3px solid #36C4D0;
    }

    .tabviews .tabview .btn-step2 {
        width: 270px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        background-color: #36C4D0;
        color: #fff;
        border-radius: 5px;
        color: #fff;
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
        align-self: center;
        margin-top: 40px;
        user-select: none;
        margin-bottom: 40px;
    }

    .form-item > .btn-upload {
        position: absolute;
        right: -156px;
        height: 50px;
        background-color: #ddd;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
        font-size: 28px;
        line-height: 50px;
        padding: 0px 8px;
    }

    .form-item > .btn-upload:active {
        opacity: 0.7;
    }

    .form-item > .label {
        width: 182px;
        white-space: nowrap;
        margin-right: 30px;
        text-align: right;
        height: 50px;
        line-height: 50px;
        color: #333333;
        font-weight: bold;
        font-size: 28px;
    }

    .form-item > .label > i {
        color: #FF0000;
    }

    .form-item > .value .value-item {
        position: relative;
        width: 124px;
        height: 50px;
        margin-right: 10px;
    }

    .form-item > .value .value-split {
        display: inline-block;
        line-height: 50px;
        color: #333333;
        font-size: 28px;
        font-weight: 400;
        text-align: right;
        margin: 0 10px;
    }

    .form-item.area > .value .value-item {
        width: 50%;
        position: relative;
    }

    .options {
        position: absolute;
        left: 0;
        top: 58px;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 180px;
        height: 250px;
        border: 1px solid #E8E8E8;
        z-index: 9999;
        background-color: #fff;
        box-shadow: 1px 1px 5px #eee;
        border-radius: 2px;
    }

    .options > .option {
        padding-left: 12px;
        height: 50px;
        line-height: 50px;
        background-color: #fff;
        border-bottom: 1px solid #E8E8E8;
        color: #999999;
        font-size: 28px;
        font-weight: 400;
    }

    .options > .option.checked {
        background-color: #ddd;
    }

    .form-item > .value .value-item .select {
        position: absolute;
        left: 0;
        top: 0;
        width: 124px;
        height: 50px;
        color: #333333;
        font-size: 28px;
        font-weight: 400;
        padding-left: 12px;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
        box-sizing: border-box;
        cursor: pointer;
    }

    .form-item.area > .value .value-item .select {
        width: 100%;
    }

    .form-item > div.value.choose::before,
    .form-item > .value .value-item .select::before {
        content: '';
        position: absolute;
        right: -1px;
        top: -1px;
        height: 50px;
        width: 20px;
        background-color: #DDDDDD;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
        box-sizing: border-box;
    }

    .form-item > div.value.choose::after,
    .form-item > .value .value-item .select::after {
        content: '';
        position: absolute;
        right: 3px;
        bottom: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 4px;
        background-image: url(../../../assets/imgs/pc/icon_xiala@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-sizing: border-box;
    }

    .tabviews .success {
        text-align: center;
        margin: 40px 0;
        color: green;
        font-weight: 400;
        font-size: 28px;
        width: 100% !important;
    }

    .tabviews .success .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .tabviews .success .row > img {
        width: 64px;
        height: 64px;
        margin-right: 12px;
    }

    .tabviews .success .row.notice {
        color: #ff0009;
    }
</style>
